<template>
  <div class="profile-layout">
    <sub-header type="profile" />
    <router-view />
  </div>
</template>

<script>
import SubHeader from '../../components/SubHeader.vue'

export default {
  name: 'ProfileLayout',
  components: {
    SubHeader
  }
}
</script>

<style scoped lang="scss">
.profile-layout {
  background-color: var(--white-black);
  min-height: calc(100vh - 70px);
}
</style>
